import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/JobTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The future is already here, it’s just not evenly distributed… join us and let’s change that. Let’s make
Voice AI available to everyone and in every language!`}</p>
    <p>{`Looking for the next challenge, the chance to build from the ground-up a suite of tools that will give a voice
to computers?`}</p>
    <p>{`If so, you should join Coqui as part of our Product Engineering team. At Coqui we’re looking to redefine how humans interact
with computers through that most natural of interfaces, speech, and we require the skills of a senior full stack
engineer to build Coqui Studio. Interested?`}</p>
    {
      /* markdownlint-disable no-trailing-punctuation */
    }
    <h3 {...{
      "id": "at-coqui-you-will",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#at-coqui-you-will",
        "aria-label": "at coqui you will permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`At Coqui you will:`}</h3>
    <ul>
      <li parentName="ul">{`Build and maintain code for Coqui Studio, our web-based AI Voice Studio`}</li>
      <li parentName="ul">{`Collaborate with our product and design experts on features and designs`}</li>
      <li parentName="ul">{`Collaborate with our ML experts to expose the full power of our TTS models to users`}</li>
      <li parentName="ul">{`Work across all levels of our tech stack, from the UI to the TTS models in the backend`}</li>
    </ul>
    <h3 {...{
      "id": "your-professional-profile",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#your-professional-profile",
        "aria-label": "your professional profile permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Your professional profile:`}</h3>
    <h4 {...{
      "id": "requirements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#requirements",
        "aria-label": "requirements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Requirements`}</h4>
    <ul>
      <li parentName="ul">{`3+ years relevant front-end experience, e.g. React, HTML, CSS, JavaScript, GraphQL…`}</li>
      <li parentName="ul">{`1+ year of professional experience with React`}</li>
      <li parentName="ul">{`Professional experience working on an SPA frontend`}</li>
      <li parentName="ul">{`3+ years relevant back-end experience, e.g. REST, PostgreSQL, Python, Django…`}</li>
      <li parentName="ul">{`1+ year of professional experience with Django`}</li>
      <li parentName="ul">{`Self-motivated and self-managing, with strong organizational skills`}</li>
      <li parentName="ul">{`Strong technical communication skills`}</li>
    </ul>
    <h4 {...{
      "id": "preferred-qualifications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#preferred-qualifications",
        "aria-label": "preferred qualifications permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Preferred Qualifications`}</h4>
    <ul>
      <li parentName="ul">{`Experience working with a remote team`}</li>
      <li parentName="ul">{`Experience with open source projects`}</li>
      <li parentName="ul">{`Experience in a startup environment`}</li>
      <li parentName="ul">{`Experience owning a project from concept to production, including proposal, discussion, and execution`}</li>
    </ul>
    <h3 {...{
      "id": "about-coqui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#about-coqui",
        "aria-label": "about coqui permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`About Coqui:`}</h3>
    <p>{`Coqui was founded by the creators of Mozilla’s text-to-speech and speech-to-text engines (over 5M downloads and
30K GitHub stars), has the backing of fantastic investors from around the globe,
has a friendly & diverse developer community, and we’re just getting started.`}</p>
    <p>{`At Coqui we are redefining how humans interact with computers through that most natural of interfaces, speech.
Our product, Coqui Studio, exposes the full power of our TTS models to creators. We believe that open and free
is better than closed and controlled. So our core text-to-speech engine is open to all, providing an evermore
critical technology, speech, to the world.`}</p>
    <p>{`Coqui is privately held, founded in 2021, and is headquartered in Berlin. We are a remote-friendly company with lots
of experience working with distributed teams.`}</p>
    <p>{`We are an equal opportunity employer and value diversity. We do not discriminate on the basis of race, religion,
color, national origin, gender, sexual orientation, age, marital status, veteran status, or disability status.`}</p>
    <h3 {...{
      "id": "how-to-apply",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-to-apply",
        "aria-label": "how to apply permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`How to Apply:`}</h3>
    <p>{`Send your CV in PDF format to `}<a parentName="p" {...{
        "href": "mailto:jobs@coqui.ai"
      }}>{`jobs@coqui.ai`}</a>{` with the subject “Application for Senior Full Stack Engineer position”.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      